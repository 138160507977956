const styles = {
    card: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
    },
    actions: {
        display:'flex',
        justifyContent:"center",
        alignItems:'center'
    }
}

export default styles