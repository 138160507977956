const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    margin: "6rem",
  },
  card: { width: "400px" },
};

export default styles;
