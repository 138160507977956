const styles = {
  layout: {
    minHeight: "100vh",
  },
  header: {
    color: "#fff",
    textAlign: "center",
    fontSize: "3rem",
    background:
      "linear-gradient(90deg, rgba(246,83,39,1) 0%, rgba(147,27,252,.95) 50%, rgba(246,83,39,1) 100%)",
    height: "5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1",
  },
  image: {
    width: "45px",
  },
  title: {
    color: "white",
    margin: "0",
  },
  footer: {
    color: "white",
    textAlign: "center",
    background:
      "linear-gradient(90deg, rgba(246,83,39,1) 0%, rgba(147,27,252,1) 50%, rgba(246,83,39,1) 100%)",
  },
};
export default styles;
