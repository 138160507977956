const FETCH_ALL_STORIES = "FETCH_ALL_STORIES";
const CREATE_STORY = "CREATE_STORY";
const UPDATE_STORY = "UPDATE_STORY";
const DELETE_STORY = "DELETE_STORY";
//const LIKE_STORY = "LIKE_STORY";
const AUTHENTICATION = "AUTHENTICATION";
const LOGOUT = "LOGOUT";

export {
    FETCH_ALL_STORIES,
    CREATE_STORY,
    UPDATE_STORY,
    DELETE_STORY,
    AUTHENTICATION,
    LOGOUT
}